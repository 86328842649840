import React from 'react'
import {FaLinkedinIn, FaFacebookF, FaInstagram, FaPinterest} from 'react-icons/fa';
import {AiOutlineTwitter} from 'react-icons/ai'
import { StaticImage } from 'gatsby-plugin-image';
import { 
    FooterContainer, 
    SocialMediaContainer,
    CopyrightContainer,
    NavIconContainer,
    IconLabelContainer,
    IconLabel
} from './Footer.elements'
import logo from '../../img/logo.png'

const Footer = () => {
    return (
        <FooterContainer>
             <div id="banner" style={{marginTop: "40px"}}/>
             <NavIconContainer>
                {/* <StaticImage src="../../img/logo2.png" height = {60} width={70}/> */}
                <IconLabelContainer>
                </IconLabelContainer>
            </NavIconContainer> 
            <SocialMediaContainer>
                {/* <a href="https://www.instagram.com/1000pluscapital/?hl=en">
                    <FaFacebookF />
                </a> */}
                <a style={{color: "black"}} href="https://www.instagram.com/1000pluscapital/?hl=en">
                    <FaInstagram />
                </a>
                <a style={{color: "black"}} href="https://pin.it/7MqITqA">
                    <FaPinterest />
                </a>
                <a style={{color: "black"}} href="https://twitter.com/1000pluscapital">
                    <AiOutlineTwitter />
                </a>
            </SocialMediaContainer>
            <div style={{marginTop: "20px"}}>
                <a href="/privacy" style={{margin: "10px", color: "black"}}>
                    Privacy Policy
                </a>
                <a href="/privacy" style={{margin: "10px", color: "black"}}>
                    Terms & Conditions
                </a>
                <a href="/risk-disclaimer" style={{margin: "10px", color: "black"}}>
                    Disclaimer
                </a>
            </div>
            <CopyrightContainer>
                <IconLabel>
                    <img
                        src={logo}
                        height={50}
                    >
                    </img>
                </IconLabel>
            </CopyrightContainer>
        </FooterContainer>
    )
}

export default Footer
