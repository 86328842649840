import React from 'react'
import {Layout, PricingBanner, PricingCards} from '../components/'
import { IntroTitle } from '../components/IntroCard/IntroCard.element'
import { MenuTitle } from '../components/Menu/Menu.elements'
import Content from './content/pricing.json'

const pricing = () => {
    return (
        <Layout>
        <div style={{display:"flex", flexDirection: "column"}}>
          <IntroTitle style={{display: "flex", justifyContent: "center", marginBottom: "30px", fontSize: "2.5rem"}}>
            Privacy Policy
          </IntroTitle>
          <div style={{margin: "10px 50px"}}>
          <MenuTitle style={{display: "flex", marginTop: "20px", marginBottom: "10px"}}>
              What personal information do we collect from the people that visit our website? 
            </MenuTitle>
            <div>
              When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, phone number or other details from which a visitor can be identified.
            </div>
            <MenuTitle style={{display: "flex", marginTop: "20px", marginBottom: "10px"}}>
            How do we use your information?
            </MenuTitle>
            <div>
              We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
            </div>
            <MenuTitle style={{display: "flex", marginTop: "20px", marginBottom: "10px"}}>
            How long will you use my personal data for?
            </MenuTitle>
            <div>
            We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.<br/><br/>
To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.<br/><br/>
In some circumstances you can ask us to delete your data:<br/>
In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you</div><br/><br/>
This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it.<br/><br/> You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law.<br/><br/> Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.


            </div>
        </div>
        </Layout>
    )
}

export default pricing
