import React from 'react'
import {Layout, PricingBanner, PricingCards} from '../components/'
import { MenuTitle } from '../components/Menu/Menu.elements'
import Content from './content/pricing.json'

const pricing = () => {
  const isBrowser = () => typeof window !== "undefined"
  const params = isBrowser() && new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  let selected = params.selected;
  let checkout = '';

  switch(selected){
    case '0':{
      checkout = 'https://commerce.coinbase.com/checkout/f2d42145-3b12-4418-bbe9-be65e34942ff';
      break;
    }
    case '1':{
      checkout = 'https://commerce.coinbase.com/checkout/9f705bd0-af6a-4a1d-8bac-81ab4d1ca40b';
      break;
    }
    case '2':{
      checkout = 'https://commerce.coinbase.com/checkout/ffaebd53-d7de-4ea8-958c-1728cc42ce87';
      break;
    }
    case '3':{
      console.log('asdffsadf')
      checkout = 'https://commerce.coinbase.com/checkout/c81b6eff-39d3-4b9e-99fa-96c659533a07';
      break;
    }
    default:
      checkout = 'https://commerce.coinbase.com/checkout/f2d42145-3b12-4418-bbe9-be65e34942ff';
  }
    return (
        <Layout>
    <div id="smart-button-container">
      <div style={{textAlign: "center"}}>
        <div style={{marginBottom: "1.25rem"}}>
          <MenuTitle style={{marginBottom: "20px"}}>Checkout</MenuTitle>
          <select style={{border: "none", fontSize: "20px", margin: "30px 0px"}} id="item-options">
          <option value="25K account" price="295">25K account - 295 USD</option>
            <option value="50K account" price="495">50K account - 495 USD</option>
            <option value="100K account" price="695">100K account - 695 USD</option>
            <option value="200K account" price="1295">200K account - 1295 USD</option>
          </select>
          <select style={{visibility: "hidden"}} id="quantitySelect"></select>
        </div>
      <div id="paypal-button-container"></div>
    <div id="coinbase" style={{margin: "30px 0px"}}>
      <a className="buy-with-crypto"
        href={checkout}>
        Buy with Crypto
      </a>
          </div>
      </div>

    <div>
    </div>
    </div>
        </Layout>
    )
}

export default pricing
