import React from 'react'
import {ContactForm, Layout} from '../components/'

const Contact = () => {
    return (
        <Layout>
            <ContactForm />
        </Layout>
    )
}

export default Contact;
