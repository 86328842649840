import React, { useEffect } from 'react'
import {Layout, HIWCard} from '../components'
import { FaqItemsContainer, FeatureItemTitle, FeatureItemDescription, FaqItem, FaqItemCategory} from '../components/Features/Features.elements'
import { MenuTitle } from '../components/Menu/Menu.elements'
import Content from './content/faq.json'

const FAQ = () => {
    const toggle = (i) => {
      let toggle = document.getElementById(`${i}`);
      if(toggle.style.display==='none')
        toggle.style.display='revert'
      else
        toggle.style.display='none'
    }

    useEffect(() =>{
      Content.faq.categories.map((cat)=>{toggle(cat)});
    },[])
    return (
        <Layout>
          <div style={{display:"flex", flexDirection: "column"}}>
          <MenuTitle style={{display: "flex", justifyContent: "center"}}>
                  FAQ
                </MenuTitle>
            <FaqItemsContainer style={{display:"flex", flexDirection: "column", alignItems: "center"}}>
                {Content.faq.categories.map((cat,j)=>{
                return(<FaqItemCategory>
                    <FeatureItemTitle style={{position: "relative",display: "flex", alignItems: "center", justifyContent: "center"}}
                          onClick={()=> toggle(cat)}
                          >
                            <div style={{width: "140px"}}>
                              {cat}
                            </div>
                            <div style={{position: "absolute",cursor: "pointer", right: "0px" }}>&#8964;</div>
                          </FeatureItemTitle>
                          <div id={cat}>

                        {Content.faq.questions.filter((item) =>  item.category==cat).map((item,i) => {
                          const show = item.title===""
                          return (
                            <FaqItem style={{marginBottom: "20px", position: "relative", width: "auto", alignItems: "center"}}> 
                        {!show && <FeatureItemTitle  style={{fontSize: "16px"}}
                          onClick={()=> toggle(i+cat)}
                          >
                            <div style={{width: "90%"}}>
                              {item.title}
                            </div>
                            <div style={{position: "absolute", right: "20px", top: "32px", cursor: "pointer" }}>&#8964;</div>
                          </FeatureItemTitle>
                        }
                          <FeatureItemDescription id={i+cat} style={{display: "none"}}>
                              {item.description}
                          </FeatureItemDescription>
                      </FaqItem>)})}
                        </div>
                  </FaqItemCategory>
                )})}
            </FaqItemsContainer>
          </div>
        </Layout>
    )
}

export default FAQ