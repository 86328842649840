
import React, {useState} from 'react'
import {
    Nav, 
    NavbarContainer, 
    NavIconContainer,
    NavMenu, 
    NavItem, 
    NavLink, 
    NavButton, 
    NavButtonContainer, 
    IconLabelContainer, 
    IconLabel
} from './Navbar.elements'
import logo from '../../img/logo.png'
import { YellowButton } from '../IntroCard/IntroCard.element';

const Navbar = () => {
    const [menu, toggleMenu] = useState(false);
    const isBrowser = () => typeof window !== "undefined"

    return (
       <Nav>
           <NavbarContainer style={{justifyContent: !menu?'space-between':'' }}>
           {!menu && <NavIconContainer to = '/'>
                <IconLabelContainer>
                    <IconLabel>
                        <img
                            className="icon"
                            src={logo}
                            height={40}
                        >
                        </img>
                    </IconLabel>
                </IconLabelContainer>
            </NavIconContainer>
            }
            { isBrowser() && window.screen.availWidth < 500 && !menu && 
            <div className="burger" style={{textAlign: "right", cursor: "pointer", padding: "10px"}} onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }
            <NavMenu className={!menu?'no-mobile':""}>
             {menu && <NavIconContainer to = '/'>
                <IconLabelContainer>
                    <IconLabel>
                        <img
                            className="icon"
                            src={logo}
                            height={30}
                        >
                        </img>
                    </IconLabel>
                </IconLabelContainer>
            </NavIconContainer> 
            }   
                 <NavItem>
                    <NavLink to="/contact">
                        Contact Us
                    </NavLink>
                 </NavItem>
                 <NavItem>
                    <NavLink to="/faq">
                        FAQ
                    </NavLink>
                 </NavItem>
                 <NavItem>
                    <NavLink bold to="/pricing">
                        <YellowButton>
                        Pricing
                        </YellowButton>
                    </NavLink>
                 </NavItem>
             </NavMenu>
             {/* <NavButtonContainer>
                <NavButton>
                    <NavLink bold to="/pricing">
                        Pricing
                    </NavLink>
                </NavButton>
             </NavButtonContainer> */}
           </NavbarContainer>
       </Nav>
    )
}

export default Navbar

