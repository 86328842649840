import React from 'react'
import {Layout, PricingBanner, PricingCards} from '../components'
import { IntroTitle } from '../components/IntroCard/IntroCard.element'
import { MenuTitle } from '../components/Menu/Menu.elements'
import Content from './content/pricing.json'

const pricing = () => {
    return (
        <Layout>
        <div style={{display:"flex", flexDirection: "column"}}>
          <IntroTitle style={{display: "flex", justifyContent: "center", marginBottom: "30px", fontSize: "2.5rem"}}>
            Risk Disclaimer
          </IntroTitle>
          <div style={{margin: "10px 50px"}}>

            <div style={{margin: "20px"}}>

All content published and distributed by 1000 PLUS CAPITAL LLC and its affiliates (collectively, the “Company”) is to be treated as general information only. None of the information provided by the Company or contained herein is intended as (a) investment advice, (b) an offer or solicitation of an offer to buy or sell, or (c) a recommendation, endorsement, or sponsorship of any security, company, or fund. Testimonials appearing on the Company’s websites may not be representative of other clients or customers and is not a guarantee of future performance or success. Use of the information contained on the Company’s websites is at your own risk and the Company and its partners, representatives, agents, employees, and contractors assume no responsibility or liability for any use or misuse of such information.

<br/><br/>Futures and forex trading contain substantial risk and is not for every investor. An investor could potentially lose all or more than the investor’s initial investment. Only risk capital—money that can be lost without jeopardizing one’s financial security or lifestyle—should be used for trading and only those individuals with sufficient risk capital should consider trading. Nothing contained herein is a solicitation or an offer to buy or sell futures, options, or forex. Past performance is not necessarily indicative of future results.

<br/><br/>CCFTC Rule 4.41 – Hypothetical or Simulated performance results have certain limitations. Unlike an actual performance record, simulated results do not represent actual trading. Also, because the trades have not actually been executed, the results may have under-or-over compensated for the impact, if any, of certain market factors, such as lack of liquidity. Simulated trading programs, in general, are also subject to the fact that they are designed with the benefit of hindsight. No representation is being made that any account will or is likely to achieve profit or losses similar to those shown.
        </div>
        </div>
        </div>
        </Layout>
    )
}

export default pricing
